import React from "react"
import {Modal} from "@uprise/modal"
// Colors
import {backgrounds} from "@uprise/colors"
// spacing
import {spacing} from "@uprise/spacing"
// Components
import {Button} from "@uprise/button"
import {Medium} from "@uprise/text"
import {H3} from "@uprise/headings"

export const EapNumber = ({isOpen, handleClose, data, ...props}) => {
	return (
		<Modal
			padding={spacing.s10}
			backgroundColor={backgrounds.white}
			width='500px'
			textAlign='center'
			isOpen={isOpen}
			handleClose={handleClose}>
			<H3 className='m-b-5'>Immediate Support</H3>
			<Medium className='m-b-5'>Please call 1300 209 371 (toll free) in Australia</Medium>
			<Medium className='m-b-5'>Please call +64 800 461 271 in New Zealand</Medium>
			<Medium className='m-b-5'>If you are outside Australia, call us at +61 2 8294 9546 </Medium>
			<Button variant='primary' size='medium' title='Close' onClick={() => handleClose()} />
		</Modal>
	)
}
