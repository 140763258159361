import React, {useEffect} from "react"
// import {bool, func, string} from "prop-types"
import Styled from "styled-components"
import {connect} from "react-redux"
// actions
import {fetchCode} from "actions/codesActions"
// utils
import {getPersonalisation, getSurveyIndex} from "helpers/utils"
// Colors
import {extended} from "@uprise/colors"
// Components
import {Layout} from "components/Shared/Layout"
import YourHealth from "components/Shared/YourHealth"
import YouHealth from "components/Shared/YouHealth"
import Profile from "components/Shared/Profile"
import Account from "components/Shared/Account"
import YourJourney from "components/Shared/YourJourney"
import Privacy from "components/Shared/Privacy"
import Logout from "components/Shared/Logout"
import Personalise from "components/Shared/Personalisation"

// Actions
import {updateUserState} from "actions/userStateActions"
import {storePersonalisation} from "actions/suggestedActions"
// UI
import {LeftPanel, RightPanel, Main} from "components/Shared/Panels"
import {Header} from "components/Shared/Panels"
import LeftMenu from "components/Shared/Menus/Left"

const Wrap = Styled.section`	background-color: ${extended.purple.six};
        width: 100%;
`

const Settings = ({userState, updateUserState, fetchCode}) => {
	// Get personalisation results
	const personalisationResults = getPersonalisation(userState.surveyResults)
	const personalisationIndex = getSurveyIndex("survey-personalisation", userState.surveyResults)

	useEffect(() => {
		fetchCode(userState.employerCode)
	}, [userState.employerCode])

	const showPersonalisation = userState?.courseType !== "rtw"

	return (
		<Layout>
			<LeftPanel>
				<LeftMenu active={5} />
			</LeftPanel>
			<Wrap>
				<Header title='You' textAlign='left' width='100%' handleBack={null} />
				<Main>
					<YouHealth className='m-b-10' surveyResults={userState.surveyResults} />
					<Profile className='m-b-10' />
					<Account className='m-b-10' />
					<YourJourney className='m-b-10' />
					{showPersonalisation && (
						<Personalise
							className='m-b-10'
							personalisationResults={personalisationResults}
							personalisationIndex={personalisationIndex}
							updateUserState={updateUserState}
						/>
					)}
					<Privacy className='m-b-10' />
					<Logout userState={userState} />
				</Main>
			</Wrap>
			<RightPanel className='d-none d-sm-none d-md-none d-lg-block' width='310px' widthLg='310px'>
				<YourHealth surveyResults={userState.surveyResults} />
			</RightPanel>
		</Layout>
	)
}

function mapStateToProps(state) {
	return {
		userState: state.userState
	}
}

export default connect(mapStateToProps, {updateUserState, storePersonalisation, fetchCode})(Settings)

// array, bool, func, number, object, string
// symbol, node, element, elementType
// instanceOf oneOf oneOfType shape, exact, func, any
Settings.propTypes = {}

Settings.defaultProps = {}
