import {get} from "helpers/api"
import {LOADING_TOPICS, STORE_TOPICS} from "./types"

export function getTopics() {
	return dispatch => {
		get("coaching-topics")
			.then(topics => {
				dispatch(loadingTopics(true))
				dispatch(storeTopics(topics))
			})
			.catch(err => {
				console.log(err)
				dispatch(loadingTopics(false))
			})
			.finally(() => {
				dispatch(loadingTopics(false))
			})
	}
}

function loadingTopics(status) {
	return {
		type: LOADING_TOPICS,
		payload: status
	}
}

function storeTopics(topics) {
	return {
		type: STORE_TOPICS,
		payload: topics
	}
}
