import React, {Fragment, useState} from "react"
import styled from "styled-components"
import {SingleDatePicker, isInclusivelyAfterDay, isInclusivelyBeforeDay} from "react-dates"
import {func, bool, instanceOf} from "prop-types"
import moment from "moment"
import Styled from "styled-components"

import "react-dates/initialize"

// UI
import {extended, primary, semantic} from "@uprise/colors"

import "react-dates/lib/css/_datepicker.css"
import CalendarIcon from "assets/images/icons/svg/icon-bookings.svg"
import CalendarIconPurple from "assets/images/png/calendar-2x.png"

const DatePickerWrapper = styled.div`
	margin-top: ${props => (props.isFirst ? "21px" : "0px")};
	margin-bottom: ${props => (props.isLast ? "0px" : "21px")};
`

const LabelStyles = Styled.label`
      font-size: ${props => (props.isFocussed ? "16px" : "16px")};
      color: ${extended.charcoal.two};
      display: block;
      margin-bottom: 4px;
      transition: font-size 0.2s;
`

const SingleDatePickerElementWrapper = styled.div`
	display: flex;
	justify-content: space-around;

	.DateInput_input {
		// height: 30px;
		font-size: 16px !important;
	}

	.DateInput_input__focused {
		border: 0px;
		font-size: 16px !important;
	}

	.SingleDatePicker {
		width: 100%;
	}

	.SingleDatePicker_picker .SingleDatePicker_picker_1 {
		border-radius: 10px;
		box-shadow: 0 4px 10px 0 rgba(219, 221, 227, 0.5);
	}

	.DayPicker {
		border-radius: 10px;
		box-shadow: 0 4px 10px 0 rgba(219, 221, 227, 0.5);
	}

	.DateInput_fang {
		bottom: 53px !important;
	}

	.SingleDatePickerInput {
		display: flex;
		justify-content: space-between;
        border-bottom:1px solid #EDF8FE;
        // padding-bottom:20px;
		// border: solid 1px #d4cbfb;
		// padding: 2px 4px 2px 8px;
		border-radius: ${props => (props.borderRadius ? `${props.borderRadius}px` : 0)};
	}

	.DateInput {
		/* width: 80%; */
	}

	.DateInput_input {
		font-family: "Proxima Nova";
		font-size: 15px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.33;
		letter-spacing: normal;
		color: #333;
		padding: 0;
	}

	.SingleDatePickerInput_calendarIcon {
		padding: 0;
	}

	.CalendarDay__selected_4 {
		background: ${primary.purple};
	}
	// .CalendarDay__default:hover{
	// 	background: ${primary.purple};
	// 	color:white
	// }

    .SingleDatePickerInput_calendarIcon {
        margin: 0px;
    }
`
const MessageStyles = Styled.label`
      font-size: 12px;
      color:  ${props => {
			if (props.focused) {
				return `${extended.blue.one}`
			} else if (props.validation?.length > 0) {
				return `${semantic.error}`
			} else {
				return `${extended.purple.five}`
			}
		}};
      display: block;
      margin-top: 8px;
      transition: font-size 0.2s;
`

const DatePicker = ({
	id,
	isFirst,
	isLast,
	isFocused,
	label,
	isRequired,
	date,
	onChange,
	allowPreviousDates,
	borderRadius,
	altIcon,
	minDate = moment(),
	maxDate,
	disableFutureDates,
	validation,
	name,
	iconPosition = "after",
	placeholder,
	dateRange
}) => {
	const [focused, setFocused] = useState(false)
	const isOutSideRangeFunction = day => {
		if (dateRange && !dateRange[moment(day).format("YYYY-MM-DD")]) return true
		if (disableFutureDates) {
			if (maxDate) {
				return !isInclusivelyBeforeDay(day, maxDate) || !isInclusivelyAfterDay(day, minDate)
			}
			return !isInclusivelyBeforeDay(day, moment())
		}
		if (allowPreviousDates) {
			return false
		}
		return !isInclusivelyAfterDay(day, minDate)
	}

	return (
		<DatePickerWrapper isFirst={isFirst} isLast={isLast}>
			{label && (
				<LabelStyles htmlFor={id} isFocused={isFocused}>
					{label} {isRequired ? " * " : ""}
				</LabelStyles>
			)}
			<SingleDatePickerElementWrapper borderRadius={borderRadius}>
				<SingleDatePicker
					onDateChange={date => onChange({target: {value: date}})}
					onFocusChange={({focused}) => setFocused(focused)}
					focused={focused}
					numberOfMonths={1}
					noBorder={true}
					date={date}
					isOutsideRange={isOutSideRangeFunction}
					displayFormat='Do MMM YYYY'
					hideKeyboardShortcutsPanel
					customInputIcon={
						<figure style={{margin: 0, width: "20px", height: "20px"}}>
							<img
								style={{width: "100%"}}
								alt='calendar'
								src={altIcon ? CalendarIconPurple : CalendarIcon}
							/>
						</figure>
					}
					inputIconPosition={iconPosition}
					withFullScreenPortal={window.innerWidth < 400}
					placeholder={placeholder}
				/>
			</SingleDatePickerElementWrapper>{" "}
			{validation?.[name] && (
				<MessageStyles htmlFor={id} validation={validation?.[name]}>
					{validation?.[name]}
				</MessageStyles>
			)}
		</DatePickerWrapper>
	)
}

DatePicker.propTypes = {
	date: instanceOf(moment).isRequired,
	onChange: func.isRequired,
	allowPreviousDates: bool
}

DatePicker.defaultProps = {
	date: "",
	onChange: () => {},
	allowPreviousDates: false
}

export default DatePicker
