let jwt = null
let dispatch = null

export let BASE_URL = "//" + window.location.host
export let API_URL = "//" + window.location.host + "/api/"

if (window.location.host === "localhost:3000") {
	API_URL = "//localhost:8080/api/"
	BASE_URL = "//localhost:8080"
}

if (window.location.host === "10.0.2.2:3000") {
	API_URL = "//10.0.2.2:8080/api/"
	BASE_URL = "//10.0.2.2:8080"
}

if (window.location.host === "localhost:3001") {
	API_URL = "//localhost:8080/api/"
	BASE_URL = "//localhost:8080"
}

if (window.location.host === "10.0.2.2:3001") {
	API_URL = "//10.0.2.2:8080/api/"
	BASE_URL = "//10.0.2.2:8080"
}

export function setJwt(newJwt) {
	jwt = newJwt
}

export function setDispatch(fn) {
	dispatch = fn
}

export function apiCall(url, method = "GET", payload = {}, contentType) {
	let headers = new Headers()

	headers.set("Content-Type", "application/json; charset=utf-8")
	// Hey developer, please don't remove these headers as they are the one keeping IE in leash
	// And, believe me you don't want IE to unleash
	headers.set("Cache-control", "private, max-age=0, no-cache, no-store")
	//headers.set("Cache-control", "private, max-age=1800")
	headers.set("pragma", "no-cache")

	if (jwt) {
		headers.set("Authorization", "JWT " + jwt)
	}

	let options = {
		method,
		headers,
		credentials: "include"
	}

	if (method === "POST" || method === "PUT") {
		options.body = JSON.stringify(payload)
	}

	if (contentType === "text/html") {
		/* global Raven */
		return fetch(API_URL + url, options)
			.then(r => {
				return r.status === 200 ? r.blob() : r.json()
			})
			.catch(err => {
				console.log(err)
				throw err
			})
	} else {
		return fetch(API_URL + url, options)
			.then(r => {
				return r.json()
			})
			.catch(err => {
				throw err
			})
			.then(r => {
				r.jwt && setJwt(r.jwt)
				return r
			})
	}
}

export function put(url, payload, contentType) {
	return apiCall(url, "PUT", payload, contentType)
}

export function post(url, payload, contentType) {
	return apiCall(url, "POST", payload, contentType)
}

export function get(url, contentType) {
	return apiCall(url, "GET", null, contentType)
}
