// const oauth_client_id = process.env.CRM_OAUTH_APP_ID || "2RUdOKRuzmVo7p2nHZLhUsrqr0P8Wndi"
// const oauth_client_secret =
// 	process.env.CRM_OAUTH_APP_PASSWORD || "5OGhXl9aX3K2m5XQONAOtOi9VSd8SLf3RaS9ptY3uhl-l1rB_VxYbQFWcZ5a7B-x"
// const oauth_domain = process.env.CRM_OAUTH_DOMAIN || "dev-4o7bx9a0.us.auth0.com"

// const oauth_audience = process.env.CRM_OAUTH_AUDIENCE || "https://dev-4o7bx9a0.us.auth0.com/api/v2/"

const oauth_client_id = process.env.REACT_APP_CRM_OAUTH_APP_ID || "7oTyv4dYqmMAedr2sD4156rViTdb53sF"
const oauth_client_secret =
	process.env.REACT_APP_CRM_OAUTH_APP_PASSWORD || "5rsiUA7WNCBadRzUHFPIAh6JKemLBQDIbSJMOymDYSYnE5_pdCyJxhwiuvM4uZXd"
const oauth_domain = process.env.REACT_APP_CRM_OAUTH_DOMAIN || "uprise.au.auth0.com"

const oauth_audience = process.env.REACT_APP_CRM_OAUTH_AUDIENCE || "https://uprise.au.auth0.com/api/v2/"

// console.log("auth0 credentials", oauth_client_id, oauth_client_secret, oauth_domain, oauth_audience)
module.exports = {
	oauth_client_id,
	oauth_client_secret,
	oauth_domain,
	oauth_audience
}
