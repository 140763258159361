import React, {useEffect} from "react"
import {Card} from "@uprise/card"
import {Layout} from "components/Shared/Layout"
import {backgrounds, primary} from "@uprise/colors"
import {spacing} from "@uprise/spacing"
import {P, Note} from "@uprise/text"
import styled from "styled-components"
import {useHistory} from "react-router-dom"

const AllianzSignupConfirmation = props => {
	const history = useHistory()
	useEffect(() => {
		if (!props?.location?.state?.email) {
			history.replace("/sign-up")
		}
	}, [])
	const Title = styled.div`
		font-size: 20px;
		color: ${primary.charcoal};
		font-weight: bold;
	`
	const ImageContainer = styled.div`
		flex-direction: row;
		justify-content: flex-start;
		align-items: center;
	`
	const Icon = styled.img`
		width: 20%;
		margin-right: 20px;
	`
	const Logo = styled.img`
		width: 10%;
		position: absolute;
		top: 20px;
		left: 20px;
	`
	const SigninButton = styled.a`
		display: flex;
		justify-content: flex-end;
		color: ${primary.purple} !important;
		text-decoration: underline !important;
	`

	return (
		<Layout backgroundColor={backgrounds.fadedPurple} rowClassName='flex-sm-column justify-content-center h-100'>
			<Logo src={require("../assets/images/allianz/logo.png")} alt='allianz-logo' />
			<Card
				backgroundColor={backgrounds.white}
				shadow={true}
				padding={spacing.s10}
				className='align-self-center h-auto align-items-center justify-content-center'
				width='50%'>
				<Title className='my-3'>{`You're almost there!`}</Title>
				<P className='my-2'>
					{`Allianz Mental Wellbeing New Zealand will verify your details and you will get an email to ${props?.location
						?.state?.email || ""} to
				let you know when you will be able to access the Allianz Mental Wellbeing app. This should only
				take up to 24 hours.`}
				</P>
				<P className='my-2'>
					{`The app is available on the Apple App Store and Google Play Store. You can get a
				head start by downloading it now. We'll let you know when you can log in.`}
				</P>
				<ImageContainer className='my-3'>
					<a
						href='https://play.google.com/store/apps/details?id=com.allianzcare.mentalwellbeing'
						target='_blank'
						rel='noreferrer'>
						<Icon src={require("../assets/images/allianz/playstore.png")} alt='playstore' />
					</a>
					<a
						href='https://apps.apple.com/na/app/allianz-care-mental-wellbeing/id1576511309'
						target='_blank'
						rel='noreferrer'>
						<Icon src={require("../assets/images/allianz/appstore.png")} alt='appstore' />
					</a>
				</ImageContainer>
				<SigninButton href='/sign-in'>Click here to Sign in</SigninButton>
			</Card>
		</Layout>
	)
}
export default AllianzSignupConfirmation
