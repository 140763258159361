export const wellbeingStressMonthlyAllianz = [
	{
		_id: 0,
		text: "Welcome to the Allianz Mental Wellbeing check",
		createdAt: new Date(),
		replyType: "dialogue",
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 1,
		text: "Regular wellbeing checks help you track and improve your mental health.",
		createdAt: new Date(),
		replyType: "dialogue",
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 2,
		text: "All your answers are completely private and confidential.",
		createdAt: new Date(),
		replyType: "dialogue",
		quickReplies: {
			_id: 4,
			_next: 5,
			optionType: "radio", // or 'checkbox',
			keepIt: false,
			surveyId: "wellbeingStressMonthly-allianz-v1",
			taskId: "survey-stress-wellbeing-monthly",
			questions: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13],
			recurring: true,
			values: [
				{
					label: "Okay",
					value: "start",
					replyType: "survey",
					questionIndex: 0
				}
			]
		},
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 5,
		text: "Thanks for your update.. 🙂",
		createdAt: new Date(),
		replyType: "dialogue",
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 6,
		_end: true,
		text: "I’ll take you back so you can learn and practice more Uprise skills",
		createdAt: new Date(),
		replyType: "dialogue",
		user: {
			_id: 2,
			name: "Uprise"
		}
	}
]
