import {get} from "helpers/api"
import React, {useEffect, useState, useMemo} from "react"
import Table from "components/AllianzApproveRejectTable"
import {Layout} from "components/Shared/Layout"
import {backgrounds} from "@uprise/colors"
import {Button} from "@uprise/button"
import styled from "styled-components"
import {text} from "@uprise/typography"
import toast, {Toaster} from "react-hot-toast"

const AllianzApproveRejectList = props => {
	const Logo = styled.img`
		width: 200px;
		padding: 20px;
	`
	const ActionButtonContainer = styled.div`
		display: flex;
		gap: 10px;
		justify-content: center;
		align-items: center;
	`
	const ApproveButton = styled(Button)`
		width: 100%;
		max-width: 60px;
		height: 32px;
		font-family: "Proxima Nova";
		font-size: ${text.t4};
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.38;
		letter-spacing: normal;
		text-align: center;
		color: #f6f4fc;
	`
	const RejectButton = styled(Button)`
		width: 100%;
		max-width: 60px;
		height: 32px;
		font-family: "Proxima Nova";
		font-size: ${text.t4};
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.38;
		letter-spacing: normal;
		text-align: center;
		color: #f6f4fc;
		background-color: #d9534f;
		border-color: #d9534f;
	`
	console.log(props)
	const type = props?.location?.state?.type
	const [allianzUsers, setAllianzUsers] = useState([])

	const getAllianzUsers = async () => {
		const toastId = toast.loading("Fetching users")
		const response = await get(`allianz-users/${type}`)
		toast.dismiss(toastId)
		console.log(response)
		if (response?.code === "success" && response?.message?.length > 0) {
			setAllianzUsers(response?.message)
		} else if (response?.code === "success" && response?.message?.length <= 0) {
			toast("No users found.")
			setAllianzUsers([])
		} else {
			toast.error("Error while fetching users.")
		}
	}
	const approveRejectUser = async (token, email, status) => {
		const toastId = toast.loading("Updating user")
		const response = await get(`approve-deny?token=${token}&email=${email}&status=${status}`)
		toast.dismiss(toastId)
		if (response?.code === "success") {
			toast.success(response?.message)
		} else {
			toast.error("Error while updating user.")
		}
		getAllianzUsers()
	}
	const ActionComponent = ({id, row}) => {
		return (
			<ActionButtonContainer>
				<ApproveButton
					title={"Approve"}
					variant={"primary"}
					// onClick={() => setDeleteModalOpen(true)}
					data-testid='approveButton'
					hover={false}
					onClick={() => approveRejectUser(row.hash, row.userEmail, "approve")}
				/>
				<RejectButton
					title={"Reject"}
					variant={"secondary"}
					// onClick={() => setDeleteModalOpen(true)}
					data-testid='rejectButton'
					hover={false}
					onClick={() => approveRejectUser(row.hash, row.userEmail, "reject")}
				/>
			</ActionButtonContainer>
		)
	}
	const columns = useMemo(
		() => [
			// { Header: "id", accessor: "id", show: false },
			{
				Header: "Policy number / Access code",
				Cell: cellInfo => {
					const cellData = cellInfo.row.original.data
					const value = cellData?.policyNumber ? cellData?.policyNumber : cellData.accessCode
					return <span>{value}</span>
				},
				disableSortBy: true,
				show: true
			},
			{
				Header: "Firstname",
				accessor: "data.firstName",
				disableSortBy: true,
				show: true
			},
			{
				Header: "Lastname",
				accessor: "data.lastName",
				disableSortBy: true,
				show: true
			},
			{
				Header: "DOB",
				accessor: "data.dob",
				disableSortBy: true,
				show: true
			},
			{
				Header: "User email",
				accessor: "userEmail",
				disableSortBy: true,
				show: true
			},
			{
				Header: "Phone number",
				accessor: "data.phone",
				disableSortBy: true,
				show: true
			},

			{
				Header: "Institution",
				accessor: "data.institutionId",
				disableSortBy: true,
				show: true
			},

			{
				Header: "Destination country",
				accessor: "data.destinationCountry",
				disableSortBy: true,
				show: true
			},

			{
				Header: "StudentID",
				accessor: "data.studentId",
				disableSortBy: true,
				show: true
			},
			{
				Header: "Gender",
				accessor: "data.gender",
				disableSortBy: true,
				show: true
			},
			{
				Header: "Nationality",
				accessor: "data.nationality",
				disableSortBy: true,
				show: true
			},

			{
				Header: "User type",
				disableSortBy: true,
				show: true,
				Cell: cellInfo => {
					console.log(cellInfo)
					const cellData = cellInfo.row.original.data
					const userType =
						cellData?.type === "international_student"
							? "International Student"
							: cellData?.type === "staff"
							? "Staff"
							: "Domestic Student"
					return <span>{userType}</span>
				}
			},

			{
				Header: "Action",
				disableSortBy: true,
				// accessor: "",
				Cell: cellInfo => <ActionComponent id={cellInfo.row.original.id} row={cellInfo.row.original} />,
				show: true
			}
		],
		[]
	)
	useEffect(() => {
		if (type) {
			getAllianzUsers()
		}
	}, [])
	const renderEmptyPage = () => {
		return(
			<b>
				You are unauthorised to view this page, please use valid 
				credentials to login.
			</b>
		)
	}
	return (
		<Layout
			backgroundColor={backgrounds.white}
			rowClassName={`flex-sm-column ${type ? 'justify-content-start' : 'justify-content-center'}  ${type ? 'align-items-start' : 'align-items-center'} h-100`}>
			<Logo src={require("../assets/images/allianz/logo.png")} alt='allianz-logo' />
			{type && <Table columns={columns} data={allianzUsers} />}
			{!type && renderEmptyPage()}
			<Toaster />
		</Layout>
	)
}
export default AllianzApproveRejectList
