// UI
import Styled from "styled-components"
import {spacing} from "@uprise/spacing"
import {backgrounds, extended} from "@uprise/colors"
import {text} from "@uprise/typography"

export const DropDownWrap = Styled.div`
	width: 100%;
	display: flex;
`
