import React from "react"
import {useHistory, useLocation} from "react-router-dom"
import Styled from "styled-components"
import {connect} from "react-redux"
import moment from "moment-timezone"
// Utils
import {getThumbnailId} from "helpers/utils"
// Colors
import {backgrounds, extended} from "@uprise/colors"
// spacing
import {spacing} from "@uprise/spacing"
// Thumbnails
import thumbnails from "assets/images/thumbnails"
// Components
import {Card} from "@uprise/card"
import {P, ExtraSmall, Medium} from "@uprise/text"
import {H6, H3} from "@uprise/headings"
import {RetinaProgressiveImage} from "@uprise/image"

const ImageWrapper = Styled.div`
      height: 108px;
	width: 200px;
      min-width: 190px;
	border-top-left-radius: 10px;
	border-top-right-radius: 10px;
      margin-bottom: 10px;
`
const CardContent = Styled.div`
      display: flex;
      flex-direction: column;

      &:hover {
            cursor: pointer;
      }
 `

const Content = Styled.div`
      padding: ${spacing.s3} ${spacing.s5} ${spacing.s5} ${spacing.s5};
      height: 140px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
`

const TextWrap = Styled.div`

`

const CardContainer = Styled.div`
	display: flex;
	justify-content: flex-start;
	flex-wrap: wrap;
	:nth-child(2n) {
		 margin-right: 0;
	}
`

const DashVideo = ({userState}) => {
	let history = useHistory()
	let location = useLocation()

	const handleClick = video => {
		history.push(`/video/allianzHome/${video.taskId}/`, {from: location.pathname})
	}

	const thumbnailId = video => getThumbnailId(video.taskId)

	const videoList = userState.homework["allianzHome"].map(taskId => {
		return {...userState.tasks[taskId], ...{taskId}}
	})

	const track = userState.meta["allianzHome"]

	return (
		<>
			<H3>{track.title}</H3>
			<Medium className='m-b-6'>{track.shortDesc}</Medium>
			<CardContainer>
				{videoList.map(video => (
					<Card
						data-testid={`skill-${video.vimeoId}-button`}
						onClick={() => handleClick(video)}
						backgroundColor={backgrounds.white}
						className={`m-b-10  m-r-5`}
						shadow={true}
						key={video.vimeoId}
						width='200px'
						height='250px'>
						<CardContent>
							<ImageWrapper>
								<RetinaProgressiveImage
									srcSets={{
										small: thumbnails[`${thumbnailId(video)}`],
										medium: thumbnails[`${thumbnailId(video)}@2x`],
										large: thumbnails[`${thumbnailId(video)}@3x`]
									}}
									overlaySrc={thumbnails[`${thumbnailId(video)}-placeholder`]}
								/>
							</ImageWrapper>
							<Content>
								<TextWrap>
									<H6 className='m-t-0 m-b-0'>{video.title}</H6>
									<P color={extended.charcoal.one} fontSize='14px'>
										{video.caption.length > 40
											? video.caption.substring(0, 40) + "..."
											: video.caption}
									</P>
								</TextWrap>
								<ExtraSmall>{`${moment(video.time, "HH:mm:ss").minutes()} mins`}</ExtraSmall>
							</Content>
						</CardContent>
					</Card>
				))}
			</CardContainer>
		</>
	)
}

function mapStateToProps(state) {
	const {userState} = state

	return {
		userState
	}
}

export default connect(mapStateToProps, null)(DashVideo)
