import React, {Fragment, useState, useEffect} from "react"
import {bool, object, string} from "prop-types"
import {useHistory, useLocation} from "react-router-dom"
import styled from "styled-components"
import Styled from "styled-components"
import {connect} from "react-redux"
import moment from "moment-timezone"
// Colors
import {backgrounds, extended, primary} from "@uprise/colors"
// Utils
import {getCoachType, getCoachingType, taskIdWhitelist} from "helpers/utils"
// UI
import {Button} from "@uprise/button"
import {Card} from "@uprise/card"
import {Container, ContainerFluid, Row, Col} from "@uprise/grid"
import {ProgressBar} from "@uprise/charts"
import {Medium, Note, Small} from "@uprise/text"
import {H2, H3, H4, H5, H6} from "@uprise/headings"
import {ProgressiveImage} from "@uprise/image"
// Components
import {CoachModal} from "components/Shared/Coach/CoachModal"
import {MoreInfoModal} from "components/Shared/Coach/MoreInfoModal"
// spacing
import {spacing} from "@uprise/spacing"
// Icons
import Icons from "constants/Icons"
// API
import {post} from "helpers/api"
// Actions
import {getCoachByEmail} from "actions/coachActions"
import {updateUserState} from "actions/userStateActions"
// Typography
import {text} from "@uprise/typography"

const CoachStyles = Styled.section`
`

const Content = Styled.div`
    padding: ${props =>
		!props.pick && props.recommended
			? `${spacing.s7}`
			: !props.pick
			? `${spacing.s7 + " " + "0px" + " " + spacing.s5 + " " + " 0px"}`
			: `${spacing.s7}`};
    display: flex;
    flex-direction: row;
    width: 100%;

    @media(max-width: 1024px) {
        flex-direction: column;
    }

    @media(max-width: 475px) {
        flex-direction: column;
    }
`

const CoachInfoWrap = Styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`

const CoachHeader = Styled.div`
    display: flex;
    flex-direction: column;

    @media(max-width: 1024px) {
        margin-top: -105px;
        margin-left: 120px;
    }

    @media(max-width: 475px) {
        margin-left: 120px;
        margin-top: -100px;
    }

`

const CoachBody = Styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    flex-wrap: wrap;

    @media(max-width: 1024px) {
        margin-top: 80px;
        flex-direction: column;
    }

    @media(max-width: 475px) {
        margin-top: 100px;
        flex-direction: column;
    }
`

const CoachSpecs = Styled.div`
    width: 100%;

    @media(max-width: 1024px) {
        margin-bottom: 30px;
    }

    @media(max-width: 475px) {
        margin-bottom: 30px;
    }
`

const CoachTimes = Styled.div`
    width: 100%;

`
const CoachBio = Styled.div`
    display: flex;
    flex-direction: column;
`

const ButtonsWrap = Styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    float: right;
    width: 100%;

    @media(max-width: 1024px) {
        flex-direction: column;
    }
`

const CoachPhoto = Styled.div`
    height: ${props => (!props.pick ? "100px" : "142px")};
    width: ${props => (!props.pick ? "100px" : "142px")};
    max-width: 142px;
    border-radius: 10px;
    margin-right: 25px;

    @media(max-width: 1024px) {
        height: 100px;
        min-width: 100px;
        max-width: 100px;
        margin-right: 0;
    }

    @media(max-width: 475px) {
        height: 100px;
        min-width: 100px;
        max-width: 100px;
        margin-right: 0;
    }
`

const ChangeCoach = Styled.div`
    margin-top: 20px;

    @media(max-width: 1024px) {
    }
`

const SpecIcon = Styled.img`
    vertical-align: top;
    margin-left: ${props => props.marginLeft};
    margin-top: ${props => props.marginTop};
    height: ${props => props.height};
    width: ${props => props.width};
    min-width: 11px;
`

const Skills = Styled.div`
    max-width: 200px;
    margin-bottom: ${spacing.s32};
    list-style-type: none;
    padding-left: ${spacing.s4};
`

const Qualifications = Styled.div`
    margin-bottom: ${spacing.s32};
    list-style-type: none;
    padding-left: ${spacing.s4};
`

const Qualification = Styled.div`
    display: flex;
    flex-direction: row;
    margin-bottom: 0;
    color: ${extended.charcoal.one};
    font-size: ${text.t6};
`

const Skill = Styled.div`
    display: flex;
    flex-direction: row;
    margin-bottom: 0;
    color: ${extended.charcoal.one};
    font-size: ${text.t6};
`

const OffDay = Styled.div`
    padding: 5px;
    max-height: 24px;
    border-radius: 5px;
    margin-right: 4px;
    background-color: ${backgrounds.fadedPurple};
    display: flex;
    flex-direction: column;
    justify-content: center;
`

const Day = Styled.div`
    padding: 5px;
    max-height: 24px;
    line-height: 1;
    border-radius: 5px;
    margin-right: 4px;
    background-color: ${extended.purple.five};
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media(max-width: 475px) {
        margin-top: 10px;
    }
`

const Availability = Styled.div`
    display: flex;
    flex-direction: 'row';
    width: 400px;


    @media(max-width: 475px) {
        width: 100%;
        flex-wrap: wrap;
        margin-top: -10px;
    }
`

const EarliestAvail = Styled.div`
    color: ${primary.purple};
    padding: 5px;
    max-height: 24px;
    line-height: 1;
    border-radius: 5px;
    margin-right: 4px;
    background-color: ${extended.purple.five};
    display: inline-flex;
`

const SmallStyled = Styled(Small)`
	p {
    font-size: ${text.t9};
}
`

const MoreWrap = Styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: ${spacing.s5};
`

const DownIcon = Styled.img`
    width: 10px;
    height: 6px;
`

const LeftWrap = Styled.div`
`

const CoachCard = ({
	className,
	coach,
	pick,
	change,
	recommended,
	getCoachByEmail,
	updateUserState,
	bookings,
	shadow = true
}) => {
	let history = useHistory()

	const [langs, setLangs] = useState()
	const [availability, setAvailability] = useState([])
	const [showCoachModal, setShowCoachModal] = useState(false)
	const [showMoreInfo, setShowMoreInfo] = useState(false)
	const [loadingSelect, setLoadingSelect] = useState(false)
	const [showMore, setShowMore] = useState(false)

	useEffect(() => {
		if (coach?.hours) {
			setAvailability(
				Object.values(coach.hours).map((day, index) => {
					switch (index) {
						case 0:
							if (day.length > 0) {
								return (
									<Day key={index}>
										<SmallStyled color={primary.purple} weight={"bold"}>
											Sun
										</SmallStyled>
									</Day>
								)
							} else {
								return (
									<OffDay key={index}>
										<SmallStyled color={extended.charcoal.three} weight={"bold"}>
											Sun
										</SmallStyled>
									</OffDay>
								)
							}
						case 1:
							if (day.length > 0) {
								return (
									<Day key={index}>
										<SmallStyled color={primary.purple} weight={"bold"}>
											Mon
										</SmallStyled>
									</Day>
								)
							} else {
								return (
									<OffDay key={index}>
										<SmallStyled color={extended.charcoal.three} weight={"bold"}>
											Mon
										</SmallStyled>
									</OffDay>
								)
							}
						case 2:
							if (day.length > 0) {
								return (
									<Day key={index}>
										<SmallStyled color={primary.purple} weight={"bold"}>
											Tues
										</SmallStyled>
									</Day>
								)
							} else {
								return (
									<OffDay key={index}>
										<SmallStyled color={extended.charcoal.three} weight={"bold"}>
											Tues
										</SmallStyled>
									</OffDay>
								)
							}
						case 3:
							if (day.length > 0) {
								return (
									<Day key={index}>
										<SmallStyled color={primary.purple} weight={"bold"}>
											Wed
										</SmallStyled>
									</Day>
								)
							} else {
								return (
									<OffDay key={index}>
										<SmallStyled color={extended.charcoal.three} weight={"bold"}>
											Wed
										</SmallStyled>
									</OffDay>
								)
							}
						case 4:
							if (day.length > 0) {
								return (
									<Day key={index}>
										<SmallStyled color={primary.purple} weight={"bold"}>
											Thurs
										</SmallStyled>
									</Day>
								)
							} else {
								return (
									<OffDay key={index}>
										<SmallStyled color={extended.charcoal.three} weight={"bold"}>
											Thurs
										</SmallStyled>
									</OffDay>
								)
							}
						case 5:
							if (day.length > 0) {
								return (
									<Day key={index}>
										<SmallStyled color={primary.purple} weight={"bold"}>
											Fri
										</SmallStyled>
									</Day>
								)
							} else {
								return (
									<OffDay key={index}>
										<SmallStyled color={extended.charcoal.three} weight={"bold"}>
											Fri
										</SmallStyled>
									</OffDay>
								)
							}
						case 6:
							if (day.length > 0) {
								return (
									<Day key={index}>
										<SmallStyled color={primary.purple} weight={"bold"}>
											Sat
										</SmallStyled>
									</Day>
								)
							} else {
								return (
									<OffDay key={index}>
										<SmallStyled color={extended.charcoal.three} weight={"bold"}>
											Sat
										</SmallStyled>
									</OffDay>
								)
							}
					}
				})
			)
		}

		// Get keys of languages and split into string
		if (coach.languages && coach.languages.length > 0) {
			setLangs(coach.languages.join(", "))
		} else if (coach.intro) {
			setLangs(
				Object.keys(coach.intro)
					.map(lang => {
						return lang.charAt(0).toUpperCase() + lang.slice(1)
					})
					.join(", ")
			)
		}
	}, [coach])

	const _handlePick = coach => {
		setLoadingSelect(true)
		post("setCoach", {
			coachEmail: coach.email
		}).then(resp => {
			getCoachByEmail(coach.email)

			post("set_intercom_coach", {
				intercomCoach: coach.email,
				coachName: coach.name
			}).then(resp => {
				updateUserState(resp.user_state)
				setShowCoachModal(true)
				setLoadingSelect(false)
			})
		})
	}

	if (coach?.name) {
		return (
			<CoachStyles className={className}>
				<Card backgroundColor={backgrounds.white} shadow={shadow}>
					<Content pick={pick} recommended={recommended}>
						<LeftWrap>
							<CoachPhoto pick={pick}>
								<ProgressiveImage
									className='m-r-6 m-b-4'
									width='100%'
									height='100%'
									borderRadius='10px'
									src={
										coach?.photos?.avatarURL
											? coach?.photos?.imageURL
											: require("assets/images/coach/default-coach.png")
									}
									overlaySrc={
										coach?.photos?.avatarURI
											? coach?.photos?.avatarURI
											: require("assets/images/coach/default-coach.png")
									}
								/>
							</CoachPhoto>
							{change && (
								<ChangeCoach>
									<Button
										className='m-t-0'
										variant='secondary'
										title={`Change ${getCoachType(bookings.duration)} `}
										size='small'
										width='full'
										onClick={() => history.push("select-coach", {from: location.pathname})}
									/>
								</ChangeCoach>
							)}
						</LeftWrap>

						<CoachInfoWrap>
							<CoachHeader>
								<H5 className='m-t-0 m-b-1'>
									{coach.name}
									{coach?.activeOn?.debug ? "- (this account is not visible regular users)" : null}
								</H5>
								<Note className='m-b-4' color={extended.charcoal.one}>
									{`${coach.gender}, ${coach.age} years old with ${coach.experience} years of experience`}
								</Note>
							</CoachHeader>

							{!pick && (
								<MoreWrap>
									<Button
										onClick={() => setShowMore(!showMore)}
										variant='text'
										size='small'
										fullWidth={false}
										style={{paddingLeft: 0}}
										title={showMore ? "See less details" : "See more details"}
									/>
									<DownIcon className='m-l-1' src={Icons.chevronDownPurple} />
								</MoreWrap>
							)}
							{(!pick ? showMore : true) && (
								<CoachBody>
									<CoachBio className='m-b-6'>
										<Medium color={extended.charcoal.one}>
											<span dangerouslySetInnerHTML={{__html: coach?.intro?.english}} />
										</Medium>
									</CoachBio>
									<CoachSpecs className=' '>
										<Row>
											<Col className='col-6 col-md-6 col-lg-6 m-b-5'>
												<SpecIcon
													className='d-inline-block'
													src={Icons.location}
													width='13px'
													height='16px'
													marginTop='4px'
													marginLeft='0px'
												/>
												<Medium className='d-inline-block align-top m-l-3'>
													{coach.location}
												</Medium>
											</Col>
											<Col className='col-6 col-md-6 col-lg-6 m-b-5'>
												<SpecIcon
													className='d-inline-block'
													src={Icons.language}
													width='14px'
													height='14px'
													marginTop='3px'
													marginLeft='0px'
												/>
												<Medium className='d-inline-block align-top m-l-3'>{langs}</Medium>
											</Col>

											{coach?.qualification && (
												<Col className='col-6 col-md-6 col-lg-6 m-b-5'>
													<SpecIcon
														className='d-inline-block'
														src={Icons.education}
														width='16px'
														height='15px'
														marginTop='3px'
														marginLeft='-2px'
													/>

													<Qualifications className='d-inline-block align-top'>
														<Qualification>{coach.qualification}</Qualification>
													</Qualifications>
												</Col>
											)}

											{coach?.specialization?.length > 0 && (
												<Col className='col-6 col-md-6 col-lg-6 m-b-5'>
													<SpecIcon
														className='d-inline-block'
														src={Icons.notebookActive}
														width='12px'
														height='16px'
														marginTop='3px'
														marginLeft='0px'
													/>
													<Skills className='d-inline-block align-top'>
														{coach?.specialization?.map((skill, index) => {
															return <Skill key={index}>{skill}</Skill>
														})}
													</Skills>
												</Col>
											)}
										</Row>
									</CoachSpecs>
									<CoachTimes>
										<Row>
											<Col className='col-6 col-md-6 col-lg-6'>
												<Note className='m-t-0 m-b-2' color={extended.charcoal.one}>
													Availability
												</Note>
												<Availability className='m-t-0 m-b-3 text--semi-bold'>
													{availability}
												</Availability>
											</Col>
											<Col className='col-6 col-md-6 col-lg-6'>
												<Note className='m-t-0 m-b-2' color={extended.charcoal.one}>
													Earliest Availability
												</Note>
												<EarliestAvail className='m-t-0 m-b-3 text--semi-bold'>
													{coach.earliestAvailability
														? `${moment(coach.earliestAvailability).format(
																"ddd, DD MMM YYYY, h:mm A"
														  )} (${moment.tz(moment.tz.guess()).zoneAbbr()})`
														: "Unavailable"}
												</EarliestAvail>
											</Col>
										</Row>
									</CoachTimes>
									<ButtonsWrap>
										{pick && coach && (
											<Fragment>
												<Button
													className='m-b-sm-3 m-t-5 m-b-5'
													variant='secondary'
													size='medium'
													title={`Learn more about ${getCoachingType(bookings.duration)} `}
													full={false}
													padding='5px 15px'
													onClick={() => setShowMoreInfo(true)}
												/>
												<MoreInfoModal
													isOpen={showMoreInfo}
													handleClose={() => setShowMoreInfo(false)}
													duration={bookings.duration}
												/>
												<Button
													className='m-l-4 m-l-sm-0 m-t-5 m-b-5'
													variant='primary'
													title={`Pick as ${getCoachType(bookings.duration)} `}
													isLoading={loadingSelect}
													size='medium'
													full={false}
													padding='5px 15px'
													onClick={() => _handlePick(coach)}
												/>
												<CoachModal
													data={coach}
													isOpen={showCoachModal}
													handleClose={() => setShowCoachModal(false)}
													duration={bookings.duration}
												/>
											</Fragment>
										)}
									</ButtonsWrap>
								</CoachBody>
							)}
							{recommended && (
								<ButtonsWrap className='m-t-5'>
									<Button
										className='m-r-4 m-r-sm-0 m-b-sm-3'
										variant='secondary'
										title='View other Uprise coaches'
										padding='5px 15px'
										size='medium'
										width='auto'
										onClick={() => history.push("select-coach", {from: location.pathname})}
									/>
									<Button
										className=''
										variant='primary'
										padding='5px 15px'
										onClick={() =>
											history.push("make-booking", {
												from: location.pathname,
												duration: bookings.duration
											})
										}
										title={`Continue with ${coach?.name?.split(" ")[0]} `}
										size='medium'
										width='auto'
									/>
								</ButtonsWrap>
							)}
						</CoachInfoWrap>
					</Content>
				</Card>
			</CoachStyles>
		)
	} else {
		return <Medium>Loading</Medium>
	}
}

const mapDispatchToProps = dispatch => ({
	getCoachByEmail: email => dispatch(getCoachByEmail(email)),
	updateUserState: state => dispatch(updateUserState(state))
})

function mapStateToProps(state) {
	return {
		bookings: state?.bookings
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(CoachCard)

CoachCard.propTypes = {
	coach: object.isRequired,
	pick: bool,
	change: bool,
	recommended: bool
}

CoachCard.defaultProps = {
	pick: false,
	change: false,
	recommended: false
}
