const intercomCustomEvents = {
	WELLBEING_CHECK_COMPLETED: "WELLBEING_CHECK_COMPLETED",
	MINDSET_MODULE_STARTED: "MINDSET_MODULE_STARTED",
	MINDSET_MODULE_COMPLETED: "MINDSET_MODULE_COMPLETED",
	COACHING_SESSION_STARTED: "COACHING_SESSION_STARTED"
}

const logIntercomEvent = async (event, metadata = {}) => {
	try {
		metadata = {...metadata, mobile: false}
		console.log("Logging intercom event:", event, metadata)
		await window.Intercom("trackEvent", event, metadata)
	} catch (err) {
		console.log("Intercom error while logging event:", err)
	}
}

export {intercomCustomEvents, logIntercomEvent}
