import React, {useState, useEffect, Fragment} from "react"
import {connect} from "react-redux"
import moment from "moment"
// Actions
import {getCoachesWithSearch, setSortBy, setFilters} from "actions/coachActions"

import {Row, Col} from "@uprise/grid"
// components
import {Button} from "@uprise/button"
import Datepicker from "components/Shared/DatePicker"
import Checkbox from "components/Shared/Checkbox"
// utils
import {get} from "helpers/api"
// Icons
import Icons from "constants/Icons"
// styles
import {
	HR,
	CheckBoxWrap,
	ParentCont,
	ChildCont,
	Label,
	DownIcon,
	TitleWrap,
	FadeWrap,
	MoreWrap,
	H3Styled,
	FooterWrap
} from "./style"

const Filters = ({closeModal, getCoachesWithSearch, setSortBy, setFilters, coaches, userState, calendar}) => {
	const [selectedLang, setSelectedLang] = useState([])
	const [location, setLocation] = useState([])
	const [selectedSpec, setSelectedSpec] = useState([])
	const [parentsSelected, setParentsSelected] = useState([])
	const [showMore, setShowMore] = useState(false)
	const [showMoreLoc, setShowMoreLoc] = useState(false)
	const [filters, setFilter] = useState({})
	const [isLoading, setIsLoading] = useState(true)
	const [date, setDate] = useState()
	const [searchLoading, setSearchLoading] = useState(false)

	useEffect(() => {
		setSelectedLang(coaches.languages)
		setSelectedSpec(coaches.specialization)
		setLocation(coaches.locations)
		if (coaches.availability) setDate(moment(coaches.availability))
		fetchFilters()
	}, [])

	const fetchFilters = () => {
		get("coach-filters")
			.then(data => {
				setIsLoading(true)
				setFilter(data)
			})
			.catch(err => console.log("Error fetching filters", err))
			.finally(() => setIsLoading(false))
	}

	const handleLangChange = (ev, lang) => {
		ev.preventDefault()
		const idx = selectedLang.indexOf(lang)
		const arr = [...selectedLang]
		if (idx !== -1) {
			arr.splice(idx, 1)
			setSelectedLang(arr)
		} else {
			arr.push(lang)
			setSelectedLang(arr)
		}
	}

	const handleLocChange = (ev, loc) => {
		ev.preventDefault()
		const idx = location.indexOf(loc)
		const arr = [...location]
		if (idx !== -1) {
			arr.splice(idx, 1)
			setLocation(arr)
		} else {
			arr.push(loc)
			setLocation(arr)
		}
	}

	const handleSpecChange = (ev, el) => {
		ev.preventDefault()
		const spec = filters.SPECIALIZATIONCHILDLEVEL[el.id]
		const idx = selectedSpec.indexOf(spec.value)
		const arr = [...selectedSpec]
		if (idx !== -1) {
			arr.splice(idx, 1)
			setSelectedSpec(arr)
		} else {
			arr.push(spec.value)
			setSelectedSpec(arr)
		}
	}

	const handleParents = id => {
		const arr = [...parentsSelected]
		const idx = arr.indexOf(id)
		if (idx !== -1) {
			arr.splice(idx, 1)
			setParentsSelected(arr)
		} else {
			arr.push(id)
			setParentsSelected(arr)
		}
	}

	const handleSearch = () => {
		try {
			setSearchLoading(true)
			let availability
			if (date) availability = moment(date, "YYYY-MM-DD").toISOString()

			getCoachesWithSearch({
				employerCode: userState.employerCode,
				languages: selectedLang,
				locations: location,
				specialization: selectedSpec,
				availability: availability
			})
			setSearchLoading(false)
			closeModal()
		} catch (err) {
			console.log("Error in sort: ", err)
			setSearchLoading(false)
		}
	}

	const handleClear = () => {
		try {
			setSelectedLang([])
			setSelectedSpec([])
			setLocation([])
			setSortBy(filters.FILTERS[0].id)
			setDate()
			setFilters({languages: [], specialization: [], availability: null, reset: true, locations: []})
		} catch (err) {
			console.log(err)
		}
	}

	return (
		<Row className='d-flex text-left'>
			<Col className='col-12 col-md-12 col-lg-12'>
				<H3Styled>Availability</H3Styled>
			</Col>
			<Col className='col-12 col-md-12 col-lg-12'>
				<Row>
					<Col className='col-3 col-md-3 col-lg-3'>
						<Datepicker
							date={date}
							dateRange={calendar.data}
							onChange={e => setDate(e.target.value)}
							isRequired={true}
							allowPreviousDates={false}
							disableFutureDates={true}
							minDate={moment().add(1, "days")}
							maxDate={moment().add(41, "days")}
							name='dob'
							id='dob'
							placeholder='Select Date'
							iconPosition={"before"}
							altIcon={true}
							isLast={true}
						/>
					</Col>
				</Row>
			</Col>
			<Col className='col-12 col-md-12 col-lg-12'>
				<HR />
			</Col>
			<Col className='col-12 col-md-12 col-lg-12'>
				<H3Styled>Specialisation</H3Styled>
			</Col>
			<Col className='col-12 col-md-12 col-lg-12'>
				{!isLoading &&
					filters.SPECIALIZATIONPARENTLEVEL.map((parent, idx) => (
						<Fragment key={idx}>
							{!parent.disabled && (
								<>
									<ParentCont onClick={() => handleParents(parent.id)}>
										<TitleWrap>{parent.value}</TitleWrap>
										<DownIcon className='m-t-1' src={Icons.chevronDownPurple} />
									</ParentCont>
									<Row>
										<Col className='col-12 col-md-12 col-lg-12'>
											<HR />
										</Col>
									</Row>
									<FadeWrap visible={parentsSelected.includes(parent.id)}>
										{filters.SPECIALIZATIONCHILDLEVEL.filter(
											child => child.parentId === parent.id
										).map((el, id) => (
											<Fragment key={el.id}>
												{!el.disabled && (
													<CheckBoxWrap htmlFor={`check${el.id}`} key={el.id}>
														<Checkbox
															checked={selectedSpec.includes(el.value)}
															onChange={ev => handleSpecChange(ev, el)}
															id={`check${el.id}`}
														/>
														<Label>{el.value}</Label>
													</CheckBoxWrap>
												)}
											</Fragment>
										))}
									</FadeWrap>
								</>
							)}
						</Fragment>
					))}
			</Col>
			<Col className='col-12 col-md-12 col-lg-12'>
				<H3Styled>Language Spoken</H3Styled>
			</Col>
			<Col className='col-12 col-md-12 col-lg-12'>
				{!isLoading &&
					filters.LANGUAGES.map((lang, idx) => {
						if (!showMore && idx > 4) return null
						else
							return (
								<CheckBoxWrap htmlFor={`lang${idx}`} key={idx}>
									<Checkbox
										onChange={ev => handleLangChange(ev, lang)}
										checked={selectedLang.includes(lang)}
										id={`lang${idx}`}
									/>
									<Label>{lang}</Label>
								</CheckBoxWrap>
							)
					})}
				{filters?.LANGUAGES?.length > 5 && (
					<MoreWrap>
						<Button
							onClick={() => setShowMore(!showMore)}
							variant='text'
							size='small'
							fullWidth={false}
							style={{paddingLeft: 0}}
							title={showMore ? "Show less" : "Show more"}
						/>
						<DownIcon className='m-l-1' src={Icons.chevronDownPurple} />
					</MoreWrap>
				)}
			</Col>
			<Col className='col-12 col-md-12 col-lg-12'>
				<H3Styled>Coach Location</H3Styled>
			</Col>
			<Col className='col-12 col-md-12 col-lg-12'>
				{!isLoading &&
					filters.LOCATIONS.map((loc, idx) => {
						if (!showMoreLoc && idx > 4) return null
						else
							return (
								<CheckBoxWrap htmlFor={`loc${idx}`} key={idx}>
									<Checkbox
										onChange={ev => handleLocChange(ev, loc)}
										checked={location.includes(loc)}
										id={`loc${idx}`}
									/>
									<Label>{loc}</Label>
								</CheckBoxWrap>
							)
					})}
				{filters?.LOCATIONS?.length > 5 && (
					<MoreWrap>
						<Button
							onClick={() => setShowMoreLoc(!showMoreLoc)}
							variant='text'
							size='small'
							fullWidth={false}
							style={{paddingLeft: 0}}
							title={showMoreLoc ? "Show less" : "Show more"}
						/>
						<DownIcon className='m-l-1' src={Icons.chevronDownPurple} />
					</MoreWrap>
				)}
			</Col>
			<Col className='col-12 col-md-12 col-lg-12'>
				<HR />
			</Col>
			<Col className='col-12 col-md-12 col-lg-12'>
				<FooterWrap>
					<Button variant={"text"} size='medium' fullWidth={false} title='Clear All' onClick={handleClear} />
					<Button
						variant={"primary"}
						size='medium'
						fullWidth={false}
						title='Apply Filter'
						isLoading={searchLoading}
						onClick={handleSearch}
					/>
				</FooterWrap>
			</Col>
		</Row>
	)
}

const mapStateToProps = state => {
	return {
		userState: state.userState,
		coaches: state?.coaches,
		calendar: state?.calendar
	}
}

const mapDispatchToProps = dispatch => ({
	setSortBy: sort => dispatch(setSortBy(sort)),
	setFilters: ({languages, specialization, availability, reset, locations}) =>
		dispatch(setFilters({languages, specialization, availability, reset, locations})),
	getCoachesWithSearch: ({employerCode, languages, specialization, availability, locations}) =>
		dispatch(getCoachesWithSearch({employerCode, languages, specialization, availability, locations}))
})

export default connect(mapStateToProps, mapDispatchToProps)(Filters)
